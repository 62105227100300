import React, { useState } from 'react'
import { domain } from '../env';
import axios from 'axios';

export default function Login() {

	const [userName, setUserName] = useState(null);
	const [password, setPassword] = useState(null);
	const [isLogin, setLogin] = useState(true)
	console.log(userName);
	console.log(password);

	const loginrequest = () => {

		const url = `${domain}/api/login`

		const data = {
			'username': userName,
			'password': password
		}

		axios.post(url, data)
			.then((resposne) => {
				console.log(resposne.data)
				window.localStorage.setItem("token", resposne.data['token'])
				window.location.href = "/"
			}).catch(_ => {
				alert("Your User Name or Password is Wrong!!");
			})
	}

	return (
		<div style={{ textAlign: 'left', paddingTop: '50px' }}>
			<h3 style={{ paddingLeft: '120px', backgroundColor: 'GrayText', color: 'white' }}>Login</h3>
			<div className="container mt-20" >
				<div class="form-group" style={{ marginTop: '10px', width: '50%' }}>
					<label for="exampleInputEmail1">User Name</label>
					<input onChange={(e) => setUserName(e.target.value)} type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Name" />
				</div>
				<div class="form-group" style={{ marginTop: '10px', width: '50%' }}>
					<label for="exampleInputPassword1">Password</label>
					<input onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" />
				</div>
				<button type="submit" onClick={loginrequest} class="btn btn-success" style={{ marginTop: '10px' }}>Submit</button>
			</div>
		</div>
	)
}
