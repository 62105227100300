import React, { Component } from 'react'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Routes
} from "react-router-dom";
import Home from './Home';
import Auth from './Auth';
import AuthTypes from './AuthTypes';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import Profile from './Profile';


export default function Navbarcom() {
    const logoutrequest = () => {
        localStorage.clear()
        window.location.href = "/login"
    }

    const tokenget = window.localStorage.getItem('token')

    return (
        <Router>
            <div>
                <Navbar bg="dark" variant={'dark'} expand="lg">
                    <Container fluid>
                        <Navbar.Brand href="#">Auth Project</Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll">
                            <Nav
                                className="me-auto my-2 my-lg-0"
                                style={{ maxHeight: '100px' }}
                                navbarScroll
                            >
                                {tokenget != null ?
                                    (
                                        <>
                                            <Nav.Link as={Link} to={"/"}>Home</Nav.Link>
                                            <Nav.Link as={Link} to={"/auth"}>Auth</Nav.Link>
                                            <Nav.Link as={Link} to={"/authtype"}>Auth Types</Nav.Link>
                                            <div class="text-end">
                                                <Nav.Link as={Link} onClick={logoutrequest}>Logout</Nav.Link>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Nav.Link as={Link} to={"/login"}>Login</Nav.Link>
                                        </>
                                    )
                                }
                            </Nav>
                            <Form className="d-flex">
                                <Form.Control
                                    type="search"
                                    placeholder="Search"
                                    className="me-2"
                                    aria-label="Search"
                                />
                                <Button bg="dark" variant="outline-success">Search</Button>
                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <Routes>
                    {tokenget != null ?
                        (
                            <>
                                <Route exact path="/" element={<Home />} />
                                <Route path="/auth" element={<Auth />} />
                                <Route path="/authtype" element={<AuthTypes />} />
                                <Route path="/logout" element={<Logout />} />
                            </>
                        )
                        :
                        (
                            <>
                                <Route path="/login" element={<Login />} />

                            </>
                        )
                    }
                </Routes>
            </div>
        </Router>
    )
}

