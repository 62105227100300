import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbarcom from './Pages/Navbarcom';
import Login from './Pages/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Navbarcom />
      {/* <Login />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router> */}
    </div>
  );
}

export default App;
