import React, { Component, Fragment, useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';


export default function Auth() {

  const [showAddNew, setShowAddNew] = useState(false);

  const handleShowAddNew = () => setShowAddNew(true);
  const handleCloseAddNew = () => setShowAddNew(false);

  const [showEditDialog, setShowEditDialog] = useState(false);

  const handleShowEditDialog = () => setShowEditDialog(true);
  const handleCloseEditDialog = () => setShowEditDialog(false);


  const [authID, setAuthId] = useState('');
  const [authPass, setAuthPass] = useState('');
  const [authTypeId, setAuthTypeId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [serviceCompany, setServiceCompany] = useState('');
  const [serviceCompanyAdd, setServiceCompanyAdd] = useState('');
  const [cardNo, setCardNo] = useState('');
  const [cardRef, setCardRef] = useState('');


  const [editID, setEditID] = useState('');
  const [editAuthID, setEditAuthId] = useState('');
  const [editAuthPass, setEditAuthPass] = useState('');
  const [editAuthTypeId, setEditAuthTypeId] = useState('');
  const [editStartDate, setEditStartDate] = useState('');
  const [editEndDate, setEditEndDate] = useState('');
  const [editServiceCompany, setEditServiceCompany] = useState('');
  const [editServiceCompanyAdd, setEditServiceCompanyAdd] = useState('');
  const [editCardNo, setEditCardNo] = useState('');
  const [editCardRef, setEditCardRef] = useState('');



  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    //.net 6 Web API URL
    {/*const url = `https://localhost:7067/api/Auths/`*/ }

    //Django Rest Framework Web API URL
    const url = `https://authproapi.akijgroup.org/getAllAuthInfoData/`
    axios.get(url)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getAuthTypes = () => {
    fetch('https://authproapi.akijgroup.org/getAuthTypes/')
      .then(response => response.json())
      .then(data => setAuthTypes(data))
      .catch(error => console.error(error));
  }

  {/*Start Get Auth Types Data For Drop Down List*/ }

  const [authTypeList, setAuthTypes] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  const [editSelectedOption, setEditSelectedOption] = useState('');

  useEffect(() => {
    getAuthTypes();
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    setAuthTypeId(event.target.value);
  }

  const handleEditChange = (event) => {
    setEditSelectedOption(event.target.value);
    setEditAuthTypeId(event.target.value);
  }

  {/*End Get Auth Types Data For Drop Down List*/ }


  const handleEdit = (id) => {
    handleShowEditDialog();
    getAuthTypes();
    //For .net 6 API URL
    {/*const url = `https://localhost:7067/api/AuthInfo/${id}`;*/ }
    // For Django Rest API URL
    const url = `https://authproapi.akijgroup.org/getAuthInfoByID/${id}/`
    axios.get(url)
      .then((result) => {
        console.log(result.data)
        setEditAuthId(result.data.authID);
        setEditAuthPass(result.data.authPass);
        setEditAuthTypeId(result.data.authType);
        setEditSelectedOption(result.data.authType);
        setEditStartDate(result.data.startDate);
        setEditEndDate(result.data.endDate);
        setEditCardNo(result.data.cardNo);
        setEditCardRef(result.data.CardRef);
        setEditServiceCompany(result.data.serviceCompany);
        setEditServiceCompanyAdd(result.data.serviceCompanyAdd);
        setEditID(id);
      }).catch((error) => {
        console.log(error);
      })
  }

  const handleDelete = (id) => {
    //For .net 6 API URL
    {/*const url = `https://localhost:7067/api/AuthTypes/${id}`*/ }
    // For Django Rest API URL
    const url = `https://authproapi.akijgroup.org/deleteAuthInfo/${id}/`
    axios.delete(url)
      .then((result) => {
        getData();
        clear();
        toast.success("Auth Info has been deleted");
      }).catch((error) => {
        toast.error(error);
      })
  }

  const clear = () => {
    setAuthId('');
    setAuthPass('');
    setAuthTypeId(0);
    setStartDate('');
    setEndDate('');
    setServiceCompany(0);
    setServiceCompanyAdd('');
    setCardNo('');
    setCardRef('');

    setEditID('');
    setEditAuthId('');
    setEditAuthPass('');
    setEditAuthTypeId(0);
    setEditStartDate('');
    setEditEndDate('');
    setEditServiceCompany(0);
    setEditServiceCompanyAdd('');
    setEditCardNo('');
    setEditCardRef('');
  }

  const handleSave = () => {
    {/*const url = 'https://localhost:7067/api/Auths';*/ }
    const url = 'https://authproapi.akijgroup.org/saveAuthInfo/';

    const data = {
      'authID': authID,
      'authPass': authPass,
      'authType': authTypeId,
      'userID': "1",
      'startDate': startDate,
      'endDate': endDate,
      'serviceCompany': serviceCompany,
      'serviceCompanyAdd': serviceCompanyAdd,
      'cardNo': cardNo,
      'CardRef': cardRef,
      'isActive': true,
      'lastUpdate': new Date(),
    }

    console.log(data)

    axios.post(url, data)
      .then((result) => {
        handleCloseAddNew();
        getData();
        clear();
        toast.success("Auth Info has been created");
      }).catch((error) => {
        toast.error(error);
      })
  }


  const handleUpdate = () => {
    //For .net 6 API URL
    {/*const url = `https://localhost:7067/api/AuthTypes/${editID}`;*/ }
    // For Django Rest API URL
    const url = `https://authproapi.akijgroup.org/updateAuthInfo/${editID}/`;
    const data = {
      "id": editID,
      'authID': editAuthID,
      'authPass': editAuthPass,
      'authType': editAuthTypeId,
      'userID': "1",
      'startDate': editStartDate,
      'endDate': editEndDate,
      'serviceCompany': editServiceCompany,
      'serviceCompanyAdd': editServiceCompanyAdd,
      'cardNo': editCardNo,
      'CardRef': editCardRef,
      'isActive': true,
      'lastUpdate': new Date(),
    }

    console.log(data);

    axios.put(url, data)
      .then((result) => {
        handleCloseEditDialog();
        getData();
        clear();
        toast.success("Auth Info has been updated");
      }).catch((error) => {
        toast.error(error);
      })
  }


  return (
    <Fragment>
      <ToastContainer />
      <div style={{ backgroundColor: "Highlight" }}>
        <Container >
          <Row>
            <Col><h3 style={{ textAlign: "left", backgroundColor: "Highlight", padding: "10px" }}> Auth </h3></Col>
            <Col style={{ marginTop: "10px", textAlign: 'right' }}><input style={{ alignSelf: "right" }} onClick={() => handleShowAddNew()} type={"button"} value="Add New +" className='btn btn-success' /></Col>
          </Row>
        </Container>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Auth ID</th>
            <th>Auth Pass</th>
            {/* <th>Auth Type</th> */}
            <th>Start Date</th>
            <th>End Date</th>
            <th>Service Company</th>
            <th>Service Company Address</th>
            <th>Card No</th>
            <th>Card No Ref</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td style={{ textAlign: 'left' }}>{item.authID}</td>
                <td style={{ textAlign: 'left' }}>{item.authPass}</td>
                {/* <td style={{ textAlign: 'left' }}>{authTypeList.find(m => m.id == item.authType)}</td> */}
                <td>
                  <Moment format='DD/MM/YYYY'>
                    {item.startDate}
                  </Moment>
                </td>
                <td>
                  <Moment format='DD/MM/YYYY'>
                    {item.endDate}
                  </Moment>
                </td>
                <td>{item.serviceCompany}</td>
                <td>{item.serviceCompanyAdd}</td>
                <td>{item.cardNo}</td>
                <td>{item.CardRef}</td>
                <td colSpan={2}>
                  <button className='btn btn-primary' onClick={() => handleEdit(item.id)}>Edit</button> | &nbsp;
                  <button className='btn btn-danger' onClick={() => handleDelete(item.id)}>Delete</button>
                </td>
              </tr>
            )
          }) : "Loading...................."
          }
        </tbody>
      </Table>

      <Modal show={showAddNew} onHide={handleCloseAddNew}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Auth</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-grid gap-3">
            <Col sm={12}>
              <input type="text" className='form-control' placeholder='Enter Auth ID' value={authID} onChange={(e) => setAuthId(e.target.value)} />
            </Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Auth Pass' value={authPass} onChange={(e) => setAuthPass(e.target.value)} /></Col>
            <Col sm={12}>
              <select className='form-control'
                value={selectedOption} onChange={handleChange}>
                <option value="">Select an option </option>
                {authTypeList.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.authTypeName}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm={12}><input type="date" className='form-control' placeholder='Enter Start Date' value={startDate} onChange={(e) => setStartDate(e.target.value)} /></Col>
            <Col sm={12}><input type="date" className='form-control' placeholder='Enter End Date' value={endDate} onChange={(e) => setEndDate(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Service Company' value={serviceCompany} onChange={(e) => setServiceCompany(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Service Company Address' value={serviceCompanyAdd} onChange={(e) => setServiceCompanyAdd(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Card No' value={cardNo} onChange={(e) => setCardNo(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Card Ref' value={cardRef} onChange={(e) => setCardRef(e.target.value)} /></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddNew}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditDialog} onHide={handleCloseEditDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Modify / Update Auth Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-grid gap-3">
            <Col sm={12}>
              <input type="text" className='form-control' placeholder='Enter Auth ID' value={editAuthID} onChange={(e) => setEditAuthId(e.target.value)} />
            </Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Auth Pass' value={editAuthPass} onChange={(e) => setEditAuthPass(e.target.value)} /></Col>
            <Col sm={12}>
              <select className='form-control'
                value={editSelectedOption} onChange={handleEditChange}>
                <option value="">Select an option </option>
                {authTypeList.map((option, index) => (
                  <option key={index} value={option.id}>
                    {option.authTypeName}
                  </option>
                ))}
              </select>
            </Col>
            <Col sm={12}><input type="date" className='form-control' placeholder='Enter Start Date' value={editStartDate} onChange={(e) => setEditStartDate(e.target.value)} /></Col>
            <Col sm={12}><input type="date" className='form-control' placeholder='Enter End Date' value={editEndDate} onChange={(e) => setEditEndDate(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Service Company' value={editServiceCompany} onChange={(e) => setEditServiceCompany(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Service Company Address' value={editServiceCompanyAdd} onChange={(e) => setEditServiceCompanyAdd(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Card No' value={editCardNo} onChange={(e) => setEditCardNo(e.target.value)} /></Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Card Ref' value={editCardRef} onChange={(e) => setEditCardRef(e.target.value)} /></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEditDialog}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}



