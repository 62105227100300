import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Moment from 'react-moment';

export default function AuthTypes() {


  const [show, setShow] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);

  const handleCloseAddNew = () => setShowAddNew(false);
  const handleShowAddNew = () => setShowAddNew(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    axios.get("http://127.0.0.1:8000/getAuthTypes/")
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const [authType, setAuthType] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActice] = useState(0);

  const [editID, setEditID] = useState('');
  const [editAuthType, setEditAuthType] = useState('');
  const [editeDscription, setEditDescription] = useState('');
  const [editIsActive, setEditIsActive] = useState(0);

  const handleEdit = (id) => {
    handleShow();
    //For .net 6 API URL
    {/*const url = `https://localhost:7067/api/AuthTypes/${id}`;*/ }
    // For Django Rest API URL
    const url = `http://127.0.0.1:8000/getAuthTypeByID/${id}`
    axios.get(url)
      .then((result) => {
        setEditAuthType(result.data.authTypeName);
        setEditDescription(result.data.description);
        setEditIsActive(result.data.isActive);
        setEditID(id);
      }).catch((error) => {
        console.log(error);
      })
  }

  const handleDelete = (id) => {

    //For .net 6 API URL
    {/*const url = `https://localhost:7067/api/AuthTypes/${id}`*/ }
    // For Django Rest API URL
    const url = `http://127.0.0.1:8000/deleteAuthType/${id}/`
    axios.delete(url)
      .then((result) => {
        getData();
        clear();
        toast.success("Auth Type has been deleted");
      }).catch((error) => {
        toast.error(error);
      })
  }

  const handleIsActiveChange = (e) => {
    if (e.target.checked) {
      setIsActice(true);
    }
    else {
      setIsActice(false);
    }
  }

  const handleEditActiveChange = (e) => {
    if (e.target.checked) {
      setEditIsActive(true);
    }
    else {
      setEditIsActive(false);
    }
  }

  const handleUpdate = () => {
    //For .net 6 API URL
    {/*const url = `https://localhost:7067/api/AuthTypes/${editID}`;*/ }
    // For Django Rest API URL
    const url = `http://127.0.0.1:8000/updateAuthType/${editID}/`;
    const data = {
      "id": editID,
      "authTypeName": editAuthType,
      "description": editeDscription,
      "isActive": editIsActive,
      "lastUpdate": new Date()
    }

    console.log(data);

    axios.put(url, data)
      .then((result) => {
        handleClose();
        getData();
        clear();
        toast.success("Auth Type has been updated");
      }).catch((error) => {
        toast.error(error);
      })
  }

  const clear = () => {
    setAuthType('');
    setDescription('');
    setIsActice(0);
    setEditAuthType('');
    setEditDescription('');
    setEditIsActive(0);
    setEditID('');
  }

  const handleSave = () => {
    //For .net 6 API URL
    {/*const url = 'https://localhost:7067/api/AuthTypes';*/ }
    // For Django Rest API URL
    const url = 'http://127.0.0.1:8000/saveAuthType/';

    const data = {
      'authTypeName': authType,
      'description': description,
      'isActive': isActive,
      'lastUpdate': new Date()
    }
    console.log(data)
    axios.post(url, data)
      .then((result) => {
        handleCloseAddNew();
        getData();
        clear();
        toast.success("Auth Type has been created");
      }).catch((error) => {
        toast.error(error);
      })
  }

  return (

    <Fragment>
      <ToastContainer />
      <div style={{ backgroundColor: "Highlight" }}>
        <Container >
          <Row>
            <Col><h3 style={{ textAlign: "left", backgroundColor: "Highlight", padding: "10px" }}> Auth Type</h3></Col>
            <Col style={{ marginTop: "10px", textAlign: 'right' }}><input style={{ alignSelf: "right" }} onClick={() => handleShowAddNew()} type={"button"} value="Add New +" className='btn btn-success' /></Col>
          </Row>
        </Container>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            {/* <th>ID</th> */}
            <th>Auth Type Name</th>
            <th>Description</th>
            <th>Is Active</th>
            <th>Lastupdate</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                {/* <td>{item.id}</td> */}
                <td style={{ textAlign: 'left' }}>{item.authTypeName}</td>
                <td style={{ textAlign: 'left' }}>{item.description}</td>
                <td>{item.isActive == true ? 'Active' : 'Inactive'}</td>
                <td>
                  <Moment format='DD/MM/YYYY HH:MM:SS'>
                    {item.lastUpdate}
                  </Moment>
                </td>
                <td colSpan={2}>
                  <button className='btn btn-primary' onClick={() => handleEdit(item.id)}>Edit</button> | &nbsp;
                  <button className='btn btn-danger' onClick={() => handleDelete(item.id)}>Delete</button>
                </td>
              </tr>
            )
          }) : "Loading...................."
          }
        </tbody>
      </Table>
      <Modal show={showAddNew} onHide={handleCloseAddNew}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Auth Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-grid gap-3">
            <Col sm={12}>
              <input type="text" className='form-control' placeholder='Enter Auth Type' value={authType} onChange={(e) => setAuthType(e.target.value)} />
            </Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Description' value={description} onChange={(e) => setDescription(e.target.value)} /></Col>
            <Col sm={12}><input type="checkbox" checked={isActive == 1 ? true : false} onChange={(e) => handleIsActiveChange(e)} value={isActive} />
              <label>Is Active</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddNew}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modify / Update Auth Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-grid gap-3">
            <Col sm={12}>
              <input type="text" className='form-control' placeholder='Enter Auth Type' value={editAuthType} onChange={(e) => setEditAuthType(e.target.value)} />
            </Col>
            <Col sm={12}><input type="text" className='form-control' placeholder='Enter Description' value={editeDscription} onChange={(e) => setEditDescription(e.target.value)} /></Col>
            <Col sm={12}><input type="checkbox" checked={editIsActive == 1 ? true : false} onChange={(e) => handleEditActiveChange(e)} value={editIsActive} />
              <label>Is Active</label>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  )
}
